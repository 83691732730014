export default function hamburger() {
document.querySelector('.l-hamburger').addEventListener('click', function(){
    document.querySelector('.l-hamburger').classList.toggle('is-active');
    document.querySelector('.l-nav').classList.toggle('is-active');
    document.querySelector('.overlay').classList.toggle('is-active');
});
document.querySelector('.overlay').addEventListener('click', function(){
    document.querySelector('.l-hamburger').classList.remove('is-active');
    document.querySelector('.l-nav').classList.remove('is-active');
    document.querySelector('.overlay').classList.remove('is-active');
});
}
import hamburger from './modules/hamburger.js';
hamburger();


//Smooth Scroll
import SmoothScroll from 'smooth-scroll';
var scroll = new SmoothScroll('a[href*="#"]', {
  ignore: '[data-scroll-ignore]', // 無効にしたいリンクのセレクタ
  header: '.l-header', // 固定ヘッダーのセレクタ
  // Speed & Easing
  speed: 500, // スクロールするスピードを指定します。
  offset: 0, // スクロールする位置を整数で指定します。
  easing: 'easeInOutCubic', // イージングを指定できます
});
